import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { RootStoreModule } from './shared/store/root-store.module';
import { CoreModule } from './shared/components/core.module';

registerLocaleData(en);
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NgZorroModule } from './shared/ng-zorro.module';
import { LoginComponent } from 'src/app/pages/authenticate/login/login.component';
import { SignupComponent } from 'src/app/pages/authenticate/signup/signup.component';
import { AuthenticateComponent } from 'src/app/pages/authenticate/authenticate.component';
import { BrowserModule } from '@angular/platform-browser';
import { AuthenticateService } from 'src/app/pages/authenticate/authenticate.service';
import { CacheInterceptor } from 'src/app/shared/interceptor/cache.interceptor';
import { ResetPasswordComponent } from 'src/app/pages/authenticate/reset-password/reset-password.component';
import { ErrorsComponent } from 'src/app/pages/authenticate/errors/errors.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgZorroModule,
    ToastrModule.forRoot({ maxOpened: 1 }),
    RootStoreModule,
    CoreModule,
    AppRoutingModule,
  ],
  declarations: [
    AuthenticateComponent,
    AppComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    ErrorsComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthenticateService,
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
