import { Component } from '@angular/core';
import { FadeAnimation } from 'src/app/pages/authenticate/login/login.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-errors-page',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
  animations: FadeAnimation,
})
export class ErrorsComponent {
  errorTitle!: string;
  errorMsg!: string;
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((err) => {
      this.errorTitle = err.type as string;
      switch (this.errorTitle) {
        case 'unsupported_response_type':
          this.errorMsg =
            'The authorization server does not support obtaining an authorization code using this method.';
          break;
        case 'invalid_client':
          this.errorMsg =
            'The client is not authorized to request an authorization code using this method.';
          break;
        case 'invalid_callback_url':
          this.errorMsg =
            'Registered callback does not match with the provided URL';
          break;
        case 'invalid_request':
          this.errorMsg =
            'The request is missing a required parameter, includes an invalid parameter value, or is otherwise malformed.';
          break;
        default:
          this.errorMsg = 'Oops! Something Bad Happened!';
          break;
      }
    });
  }
}
