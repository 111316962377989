import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { environment } from 'src/environments/environment';

export enum PageType {
  login = 'login',
  signup = 'signup',
  error = 'error',
  password = 'password',
}

export interface ComponentView {
  currentForm: string;
  nextFormURL: string;
  nextFormName: string;
}

export interface AuthCodeQueries extends Record<string, string | undefined> {
  code_challenge: string;
  state: string;
  client_id: string;
  redirect_uri?: string;
}

export interface UserLoginInfo {
  email: string;
  password: string;
  invitationCode?: string;
  codeResetPassword?: string;
  newPassword?: string;
}

export type AuthorizeDTO = UserLoginInfo & AuthCodeQueries;

@Injectable()
export class AuthenticateService {
  formValidationSubject$ = new BehaviorSubject<boolean>(false);
  formValuesSubject$ = new BehaviorSubject<UserLoginInfo | undefined>(
    undefined
  );
  formPageType$ = new BehaviorSubject<PageType | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private notification: NotificationsService
  ) {}

  nextValues(dto: UserLoginInfo | undefined, isValid: boolean): void {
    this.formValuesSubject$.next(dto);
    this.formValidationSubject$.next(isValid);
  }
  nextPage(page: PageType): void {
    this.formPageType$.next(page);
  }

  submit(requestQueries?: AuthCodeQueries): Observable<unknown> {
    const baseDTO = this.formValuesSubject$.value as UserLoginInfo;
    void this.spinner.show('login-spinner');
    if (this.formPageType$.value === PageType.login) {
      const authorization: AuthorizeDTO = {
        ...(requestQueries as AuthCodeQueries),
        ...baseDTO,
      };
      return this.http
        .post<{ url: string }>(
          `${environment.apiRoot}/authorize`,
          authorization
        )
        .pipe(
          take(1),
          tap((res) => {
            window.location.href = res.url;
          })
        );
    } else if (this.formPageType$.value === PageType.password) {
      return this.requestHandler(
        this.http.post(`${environment.apiRoot}/password-reset/consume`, baseDTO)
      );
    } else {
      return this.requestHandler(
        this.http.post(`${environment.apiRoot}/signup`, baseDTO)
      );
    }
    //TODO signup
  }

  private requestHandler(http: Observable<unknown>): Observable<unknown> {
    return http.pipe(
      take(1),
      tap(() => {
        window.location.href = environment.appRoot;
      })
    );
  }
}
