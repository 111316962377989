<div class="page-title" @insertRemoveDelayed>
  <h1 class="soft-text">Login</h1>
</div>
<form *ngIf="formGroup" nz-form [formGroup]="formGroup" @insertRemoveTrigger>
  <nz-form-item>
    <nz-form-control class="transparent-autofill">
      <input
        class="{{ getErrorClass('email') }}"
        nz-input
        type="input"
        formControlName="email"
        placeholder="email"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control class="transparent-autofill">
      <input
        class="{{ getErrorClass('password') }}"
        nz-input
        type="password"
        formControlName="password"
        placeholder="password"
      />
    </nz-form-control>
  </nz-form-item>
</form>
