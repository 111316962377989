/* eslint-disable @typescript-eslint/no-empty-function */
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  AuthCodeQueries,
  AuthenticateService,
  ComponentView,
  PageType,
} from 'src/app/pages/authenticate/authenticate.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

const WaitForAnimation = [
  trigger('host', [
    transition(':enter, :leave', [
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
  trigger('imageTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('600ms 600ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0ms', style({ opacity: 0 })),
    ]),
  ]),
];

@Component({
  selector: 'app-authenticate-page',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
  animations: [...WaitForAnimation],
})
export class AuthenticateComponent {
  @HostBinding('@host') hostTrigger!: void;
  PageType = PageType;
  currentPage$: Observable<PageType>;
  componentView$: Observable<ComponentView>;
  requestQueries!: AuthCodeQueries;

  formValidationSubject$;
  showLogo = false;
  validQueries?: boolean;

  constructor(
    private route: ActivatedRoute,
    private authenticator: AuthenticateService,
    private spinner: NgxSpinnerService,
    private notification: NotificationsService
  ) {
    this.formValidationSubject$ = this.authenticator.formValidationSubject$;
    this.currentPage$ = this.route.params.pipe(
      map((params) => params.pageType as PageType),
      tap((pageType) => {
        this.showLogo = pageType === PageType.login;
      })
    );
    this.componentView$ = this.currentPage$.pipe(
      map((currentPage) => this.getPageView(currentPage))
    );
    this.route.queryParams.pipe(take(1)).subscribe((queries) => {
      const requestQueries = {
        code_challenge: (queries.code_challenge as string) ?? null,
        state: (queries.state as string) ?? null,
        client_id: (queries.client_id as string) ?? null,
        redirect_uri: (queries.redirect_uri as string) ?? null,
        code_challenge_method:
          (queries.code_challenge_method as string) ?? null,
      };
      this.validQueries =
        requestQueries.client_id != undefined &&
        requestQueries.code_challenge != undefined &&
        requestQueries.state != undefined;
      this.requestQueries = requestQueries;
    });
  }

  getButtonClass$(): Observable<string> {
    return this.formValidationSubject$.pipe(
      map((valid) => (valid ? 'very-soft-button enabled' : 'very-soft-button'))
    );
  }

  async submit(): Promise<void> {
    void this.spinner.show('login-spinner');
    try {
      await this.authenticator.submit(this.requestQueries).toPromise();
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.notification.error(
          (e.error as { error_description: string }).error_description
        );
      } else {
        this.notification.error((e as Error).message);
      }
    }
    void this.spinner.hide('login-spinner');
  }

  getPageView(page: PageType): ComponentView {
    switch (page) {
      case PageType.login:
        return {
          currentForm: 'Login',
          nextFormName: 'Sign Up',
          nextFormURL: '/signup',
        };
      case PageType.signup:
        return {
          currentForm: 'Sign Up',
          nextFormName: 'Login',
          nextFormURL: '/login',
        };
      case PageType.password:
        return {
          currentForm: 'Reset Password',
          nextFormName: '',
          nextFormURL: '',
        };
      default:
        return {
          currentForm: 'Error',
          nextFormName: '',
          nextFormURL: '',
        };
    }
  }
}
