<div class="container">
  <div class="content concave" *ngIf="(componentView$ | async) !== undefined">
    <a
      class="soft-button company-logo"
      href="https://archivvr.com"
      *ngIf="showLogo"
      @imageTrigger
    >
      <img [src]="'assets/img/ArchivVR_notext_small.png'" alt="logo" />
    </a>
    <app-login-page
      [authQueries]="requestQueries"
      *ngIf="(currentPage$ | async) === PageType.login"
    ></app-login-page>
    <app-signup-page
      [validQueries]="validQueries"
      *ngIf="(currentPage$ | async) === PageType.signup"
    ></app-signup-page>
    <app-reset-password-page
      *ngIf="(currentPage$ | async) === PageType.password"
    ></app-reset-password-page>
    <app-errors-page
      *ngIf="(currentPage$ | async) === PageType.error"
    ></app-errors-page>

    <div
      class="form-buttons-container"
      *ngIf="(currentPage$ | async) !== PageType.error"
    >
      <div class="long-button">
        <button
          class="{{ getButtonClass$() | async }}"
          (click)="submit()"
          [disabled]="(formValidationSubject$ | async) === false"
        >
          {{ (componentView$ | async)?.currentForm }}
        </button>
      </div>
      <div
        class="short-button"
        *ngIf="validQueries && (componentView$ | async)?.nextFormURL as url"
      >
        <a
          [routerLink]="(componentView$ | async)?.nextFormURL"
          [queryParamsHandling]="'preserve'"
        >
          <button class="very-soft-button enabled">
            {{ (componentView$ | async)?.nextFormName }}
          </button></a
        >
      </div>
    </div>
  </div>

  <app-spinner name="login-spinner"></app-spinner>
</div>
