<div class="page-title" @insertRemoveDelayed>
  <h1 class="soft-text">Reset Password</h1>
</div>
<form *ngIf="formGroup" nz-form [formGroup]="formGroup" @insertRemoveTrigger>
  <nz-form-item>
    <nz-form-control class="transparent-autofill">
      <input
        class="{{ getErrorClass('email') }}"
        nz-input
        type="text"
        formControlName="email"
        placeholder="email"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control class="transparent-autofill">
      <input
        class="{{ getErrorClass('password') }}"
        nz-input
        type="password"
        formControlName="password"
        placeholder="password"
      />
    </nz-form-control>
  </nz-form-item>
  <ng-container *ngIf="formGroup.controls.password as control">
    <ng-container *ngIf="!control.hasError('require') && hasAnyPasswordError">
      <div class="check-list soft-box">
        <ng-container
          [ngTemplateOutlet]="requirementChecklist"
          [ngTemplateOutletContext]="{
            isError: hasPasswordError('hasEightChar'),
            label: '8 characters longs'
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="requirementChecklist"
          [ngTemplateOutletContext]="{
            isError: hasPasswordError('hasLowercase'),
            label: 'Lower case letters (a-z)'
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="requirementChecklist"
          [ngTemplateOutletContext]="{
            isError: hasPasswordError('hasUppercase'),
            label: 'Upper case letters (A-Z)'
          }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="requirementChecklist"
          [ngTemplateOutletContext]="{
            isError: hasPasswordError('hasDigit'),
            label: 'Numbers or special characters (!#$%)'
          }"
        ></ng-container>
      </div>
    </ng-container>
  </ng-container>
  <nz-form-item *ngIf="!hasAnyPasswordError">
    <nz-form-control class="transparent-autofill">
      <input
        class="{{ getConfirmPasswordClass() }}"
        nz-input
        type="password"
        formControlName="confirmPassword"
        placeholder="confirm"
      />
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #requirementChecklist let-isError="isError" let-label="label">
  <div class="check-list-item">
    <i
      *ngIf="isError"
      nz-icon
      nzType="close"
      nzTheme="outline"
      class="check-list-item-error"
    ></i>
    <i
      *ngIf="!isError"
      nz-icon
      nzType="check"
      nzTheme="outline"
      class="check-list-item-complete"
    ></i>
    <p
      class="{{
        isError ? 'check-list-item-error' : 'check-list-item-complete'
      }}"
    >
      {{ label }}
    </p>
  </div>
</ng-template>
