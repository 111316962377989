import { Component, Input, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AuthenticateService,
  PageType,
  UserLoginInfo,
} from 'src/app/pages/authenticate/authenticate.service';
import { FadeAnimation } from 'src/app/pages/authenticate/login/login.component';
import { ActivatedRoute } from '@angular/router';
import {
  digitOrSpecialValidator,
  lowercaseValidator,
  uppercaseValidator,
  lengthValidator,
} from 'src/app/pages/authenticate/signup/signup.component';

export type ResetPasswordDTO = UserLoginInfo & {
  confirmPassword?: string;
};

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: FadeAnimation,
})
export class ResetPasswordComponent implements OnDestroy {
  @Input() validQueries?: boolean;
  formGroup!: FormGroup;

  private subscription: Subscription;
  private resetPasswordDTO: Partial<ResetPasswordDTO> = {};

  constructor(
    private formBuilder: FormBuilder,
    private authenticator: AuthenticateService,
    private route: ActivatedRoute
  ) {
    this.authenticator.nextPage(PageType.password);
    this.formGroup = this.formBuilder.group({
      email: new FormControl(undefined, [Validators.required]),
      password: new FormControl(undefined, [
        Validators.required,
        digitOrSpecialValidator,
        lowercaseValidator,
        uppercaseValidator,
        lengthValidator,
      ]),
      confirmPassword: new FormControl(undefined, [Validators.required]),
    });

    this.route.queryParams.subscribe((params) => {
      this.resetPasswordDTO.codeResetPassword = params.code as string;
    });

    this.subscription = this.formGroup.valueChanges.subscribe(
      (values: ResetPasswordDTO) => {
        this.onValidateForm(values);
        this.resetPasswordDTO.newPassword = values.password;
        this.resetPasswordDTO.email = values.email;
        this.authenticator.nextValues(
          this.resetPasswordDTO as UserLoginInfo,
          this.formIsReady
        );
      }
    );
  }

  get hasAnyPasswordError(): boolean {
    return (
      this.formGroup.controls.password.hasError('hasDigit') ||
      this.formGroup.controls.password.hasError('hasLowercase') ||
      this.formGroup.controls.password.hasError('hasUppercase')
    );
  }

  get formIsReady(): boolean {
    if (
      this.formGroup.controls.confirmPassword.hasError('confirmation') ||
      this.formGroup.controls.email.hasError('required')
    ) {
      return false;
    }

    return true;
  }

  hasPasswordError(error: string): boolean {
    return this.formGroup.controls.password.hasError(error);
  }

  onValidateForm(values: ResetPasswordDTO): void {
    //end
    if (values.confirmPassword !== values.password) {
      this.formGroup.controls.confirmPassword.setErrors({ confirmation: true });
    } else {
      this.formGroup.controls.confirmPassword.setErrors({
        confirmation: undefined,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getErrorClass(controlName: string): string {
    const control = this.formGroup.controls[controlName];
    return control.hasError('required') && control.touched
      ? 'soft-shadow-error'
      : '';
  }

  getConfirmPasswordClass(): string {
    const control = this.formGroup.controls['confirmPassword'];
    return control.hasError('required') || control.hasError('confirmation')
      ? 'soft-shadow-error'
      : '';
  }
}
