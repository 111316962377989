import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AuthCodeQueries,
  AuthenticateService,
  AuthorizeDTO,
  PageType,
} from 'src/app/pages/authenticate/authenticate.service';

export const FadeAnimation = [
  trigger('insertRemoveTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('600ms 300ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('200ms', style({ opacity: 0 })),
    ]),
  ]),
  trigger('insertRemoveDelayed', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('600ms 600ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('200ms', style({ opacity: 0 })),
    ]),
  ]),
];

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: FadeAnimation,
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() authQueries!: AuthCodeQueries;
  formGroup!: FormGroup;

  private subscription!: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authenticator: AuthenticateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      this.authQueries.client_id == undefined ||
      this.authQueries.code_challenge == undefined ||
      this.authQueries.state == undefined
    ) {
      void this.router.navigate(['/error'], {
        queryParams: { type: 'invalid_request' },
      });
    }
    this.authenticator.nextValues(undefined, false);
    this.authenticator.nextPage(PageType.login);

    this.formGroup = this.formBuilder.group({
      email: new FormControl(undefined, [Validators.required]),
      password: new FormControl(undefined, [Validators.required]),
    });
    this.subscription = this.formGroup.valueChanges.subscribe(() =>
      this.authenticator.nextValues(
        this.formGroup.value as AuthorizeDTO,
        this.formGroup.valid
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getErrorClass(controlName: string): string {
    //'.soft-shadow-error';
    const control = this.formGroup.controls[controlName];
    return control.hasError('required') && control.touched
      ? 'soft-shadow-error '
      : '';
  }
}
