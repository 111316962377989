import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { NgZorroModule } from '../ng-zorro.module';
import { TooltipModule } from './tooltip/tooltip.module';

@NgModule({
  declarations: [AppSpinnerComponent],
  imports: [CommonModule, NgxSpinnerModule, NgZorroModule],
  exports: [AppSpinnerComponent, TooltipModule, NgxSpinnerModule],
})
export class CoreModule {}
