import { Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AuthenticateService,
  UserLoginInfo,
} from 'src/app/pages/authenticate/authenticate.service';
import { FadeAnimation } from 'src/app/pages/authenticate/login/login.component';

export const lengthValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const val = (control.value as string) ?? '';
  return val.length > 8 ? null : { hasEightChar: { value: val } };
};

export const uppercaseValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const regex = /(?=.*[A-Z])/;
  const val = control.value as string;
  const hasUppercase = regex.test(val);
  return hasUppercase ? null : { hasUppercase: { value: val } };
};

export const lowercaseValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const regex = /(?=.*[a-z])/;
  const val = control.value as string;
  const hasLowercase = regex.test(val);
  return hasLowercase && val?.length > 0
    ? null
    : { hasLowercase: { value: val } };
};

export const digitOrSpecialValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const regex = /(?=.*[\d!@#$%^&*)(+=._-])/;
  const val = control.value as string;
  const hasDigit = regex.test(val);
  return hasDigit ? null : { hasDigit: { value: val } };
};

export type SignupDTO = UserLoginInfo & {
  confirmPassword: string;
  hasInvitationCode: boolean;
};

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: FadeAnimation,
})
export class SignupComponent implements OnDestroy {
  @Input() validQueries?: boolean;
  formGroup!: FormGroup;

  private subscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private authenticator: AuthenticateService
  ) {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(undefined, [Validators.required]),
      password: new FormControl(undefined, [
        Validators.required,
        digitOrSpecialValidator,
        lowercaseValidator,
        uppercaseValidator,
        lengthValidator,
      ]),
      confirmPassword: new FormControl(undefined, [Validators.required]),
      hasInvitationCode: new FormControl(true, [Validators.required]),
      invitationCode: new FormControl(undefined),
    });
    this.subscription = this.formGroup.valueChanges.subscribe(
      (values: SignupDTO) => {
        this.onValidateForm(values);
        this.authenticator.nextValues(
          {
            email: values.email,
            password: values.password,
            invitationCode: values.invitationCode,
          },
          this.formIsReady
        );
      }
    );
  }

  get formValues(): SignupDTO {
    return this.formGroup.value as SignupDTO;
  }

  get hasAnyPasswordError(): boolean {
    return (
      this.formGroup.controls.password.hasError('hasDigit') ||
      this.formGroup.controls.password.hasError('hasLowercase') ||
      this.formGroup.controls.password.hasError('hasUppercase')
    );
  }

  get formIsReady(): boolean {
    if (
      this.formGroup.controls.confirmPassword.hasError('confirmation') ||
      this.formGroup.controls.invitationCode.hasError('required')
    ) {
      return false;
    }

    return true;
  }

  hasPasswordError(error: string): boolean {
    return this.formGroup.controls.password.hasError(error);
  }

  onValidateForm(values: SignupDTO): void {
    //TEMPORARY
    if (!values.hasInvitationCode) {
      this.formGroup.controls.hasInvitationCode.setErrors({ required: true });
    } else {
      this.formGroup.controls.hasInvitationCode.setErrors(null);
    }
    //end
    if (values.confirmPassword !== values.password) {
      this.formGroup.controls.confirmPassword.setErrors({ confirmation: true });
    } else {
      this.formGroup.controls.confirmPassword.setErrors({
        confirmation: undefined,
      });
    }

    if (values.hasInvitationCode && values.invitationCode == undefined) {
      this.formGroup.controls.invitationCode.setErrors({ required: true });
    } else {
      this.formGroup.controls.invitationCode.setErrors(null);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getErrorClass(controlName: string): string {
    const control = this.formGroup.controls[controlName];
    return control.hasError('required') && control.touched
      ? 'soft-shadow-error'
      : '';
  }

  getConfirmPasswordClass(): string {
    const control = this.formGroup.controls['confirmPassword'];
    return control.hasError('required') || control.hasError('confirmation')
      ? 'soft-shadow-error'
      : '';
  }
}
